import React, { useState, useEffect } from "react";
import styled from "styled-components";

import useWindowSize from "utils/useWindowSize";
import ServicesTemplate from "templates/ServicesTemplate";
import { globalHistory } from "@reach/router";
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl";
import ProductsDesktop from "components/ProductsDesktop/ProductsDesktop";
import ProductsMobile from "components/ProductsMobile/ProductsMobile";

import Banner from "../../../static/nawozBaner.png";
import Form from "../../components/Form/Form";
import iconClose from "../../../static/close.svg";
import FormModal from "../../components/FormModal/FormModal";
import noScroll from "no-scroll";

const StyledProductSquaresContainer = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: minmax(auto, 1fr);
  grid-gap: 20px;

  @media (min-width: 1200px) {
    display: none;
  }
`;
const FirstSection = styled.div`
  width: 100%;
  height: 300px;
  background-image: url(${Banner});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  margin-bottom: 120px;
  padding-inline-start: 130px;
  padding-top: 60px;
  flex-direction: column;
  justify-content: end;
  color: white;

  @media (max-width: 768px) {
    margin-bottom: 80px;
    padding-inline-start: 40px;
  }

  @media (max-width: 425px) {
    padding-inline-start: 10px;
  }
`;
const KredoLink = styled.a`
  cursor: pointer;
  color: rgb(34, 218, 52);
  text-decoration: underline;
`;

const Title = styled.h1`
  font-size: 72px;

  @media (max-width: 425px) {
    font-size: 30px;
  }
`;
const TextP = styled.p`
  width: 80%;
`;
const ProductForm = styled.div`
border:1px solid #00ad11;
  position: fixed;
  top: 50%;
  left: 50%;
  height: max-content;
  transform: translate(-50%, -50%);
  width: 70%;
  background: white;
  z-index: 10;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding:20px;
  max-width:700px;

  @media(max-width:550px){
    width:90%;
    padding:5px;
    padding-block:10px;
    flex-direction:column;
    height:max-content;
  }
`;

const TitleForm = styled.p`
  font-weight: 700;
  font-size: 36px  ;
  line-height: 41px;
  text-align: center;
  color: #00ad11;

  @media(max-width:700px){
    font-size:20px;
  }
`;

function Fertilizers() {
  const size = useWindowSize();
  const intl = useIntl();
  const path = globalHistory.location.pathname;
  const [showForm, setShowForm] = useState(false);
  const [currentProd, setCurrentProd] = useState();
  const [success, setSuccess] = useState(false);

  const updateShowForm = (e) => {
    setCurrentProd(e);
    setShowForm(true);

  };
  const closePopUp = () => {
    setShowForm(false);
  };

  useEffect(() => {
    if (showForm) {
      noScroll.on()
    } else {
      noScroll.off()
    }
  }, [showForm])


  return (
    <ServicesTemplate>
      <>
        {/* {size.width >= 1200 ? (
          <ProductsDesktop />
        ) : (
          <StyledProductSquaresContainer>
            <ProductsMobile />
          </StyledProductSquaresContainer>
        )} */}

        {showForm ? (
          <ProductForm>
            <img
              onClick={closePopUp}
              src={iconClose}
              style={{
                cursor: "pointer",
                width: "20px",
                position: "absolute",
                left: "96%",
                top: "10px",
              }}
            />
            <TitleForm>
              <FormattedMessage id="contact.form" />
            </TitleForm>
            {path.includes("/uk/") ? (
              <TextP style={{ width: "auto", fontSize:'15px' }}>
                <FormattedMessage id="services.fertilizersSubtitle" />
                <KredoLink  target="_blank" href="https://kredobank.com.ua/business/malomu-ta-serednomu-biznesu/agrobiznes/kredytna-liniya-agro" >

                  <FormattedMessage id="services.Kredobank" />
                </KredoLink>
                .
              </TextP>
            ) : (
              <TextP style={{ width: "auto",fontSize:'15px' }}>
                <FormattedMessage id="services.fertilizersSubtitle" />
                <KredoLink  target="_blank" href="https://kredobank.com.ua/business/malomu-ta-serednomu-biznesu/agrobiznes/kredytna-liniya-agro" >

                  <FormattedMessage id="services.Kredobank" />
                </KredoLink>
                <FormattedMessage id="services.fertilizersSubtitle2" />
              </TextP>
            )}
            <Form idprod={currentProd} setShowModal={setSuccess} setPopUp={setShowForm}  />
          </ProductForm>
        ) : null}
        <FormModal showModal={success} />
        <FirstSection>
          <Title>
            <FormattedMessage id="services.fertilizers" />
          </Title>

          {path.includes("/uk/") ? (
            <TextP>
              <FormattedMessage id="services.fertilizersSubtitle" />
              <KredoLink  target="_blank" href="https://kredobank.com.ua/business/malomu-ta-serednomu-biznesu/agrobiznes/kredytna-liniya-agro" >
                 <FormattedMessage id="services.Kredobank" />
              </KredoLink>
              .
            </TextP>
          ) : (
            <TextP>
              <FormattedMessage id="services.fertilizersSubtitle" />
              <KredoLink  target="_blank" href="https://kredobank.com.ua/business/malomu-ta-serednomu-biznesu/agrobiznes/kredytna-liniya-agro" >
                    <FormattedMessage id="services.Kredobank" />
              </KredoLink>
              <FormattedMessage id="services.fertilizersSubtitle2" />
            </TextP>
          )}
        </FirstSection>

        <ProductsDesktop openPopUp={updateShowForm} />
      </>
    </ServicesTemplate>
  );
}

export default Fertilizers;
