import React, { createRef, useState } from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import { Link, FormattedMessage } from "gatsby-plugin-intl";
import { globalHistory } from "@reach/router";
import Form from "../Form/Form";

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.services.textBlack};
  font-family: "Roboto";
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSize.xxxxxs};
  text-align: center;
  text-decoration: underline;
`;

const StyledTable = styled.table`
  width: 100%;
  height: auto;
  margin: 0;
  border-collapse: collapse;
  margin-bottom: 30px;
  thead {
    background-color: ${({ theme }) => theme.colors.services.tableBlackPart};

    th {
      color: ${({ theme }) => theme.colors.services.textWhite};
      font-family: "Roboto";
      text-transform: uppercase;
      font-size: ${({ theme }) => theme.fontSize.xxxxxs};
      text-align: center;
      border-right: ${({ theme }) =>
        `1px solid ${theme.colors.services.tableBackground}`};

      &:last-of-type {
        border-right: ${({ theme }) =>
          `1px solid ${theme.colors.services.tableBlackPart}`};
      }
    }
  }
  tbody {
    background-color: ${({ theme }) => theme.colors.services.tableBackground};

    td {
      text-align: center;
      font-size: ${({ theme }) => theme.fontSize.xxxxs};
      font-family: "Roboto";
      border: ${({ theme }) =>
        `1px solid ${theme.colors.services.tableBlackPart}`};

      &:first-of-type {
        padding-left: 0.96667rem;
        padding-right: 0.96667rem;
        padding-top: 0.725rem;
        padding-bottom: calc(0.725rem - 1px);
        font-weight: ${({ theme }) => theme.fontWeights.medium};
      }
    }
  }
`;

const StyledDescription = styled.p`
  margin: 0;
  text-align: justify;
`;

const ProductList = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 70px;
  column-gap: 40px;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 70px;

   @media(max-width:884px){
    justify-content:center;
   }
`;

const ProductBlock = styled.div`
  max-width: 380px;
  width: 100%;
  position: relative;
  border-radius: 4px 4px 0 0;
  background: #00ad11;

  :hover {
    background: rgb(0, 79, 158);
  }

  button {
    display: none;
  }

  :hover > button {
    display: block;
  }
`;

const ProductsMobile = ({openPopUp}) => {

  const [columnTitles] = useState([
    { id: "services.fieldOne", width: "60%", colSpan: "2" },
    { id: "services.fieldTwo" },
    { id: "services.fieldThree" },
    { id: "services.fieldFour" },
    { id: "services.fieldFive" },
  ]);

  const path = globalHistory.location.pathname;

  const setDescription = (en, pl, fr, uk) => {
    const regexEN = /en/i;
    const regexPL = /pl/i;
    const regexRU = /fr/i;
    const regexUK = /uk/i;

    if (path.match(regexEN) && en) {
      return <StyledDescription>{en}</StyledDescription>;
    } else if (path.match(regexPL) && pl) {
      return <StyledDescription>{pl}</StyledDescription>;
    } else if (path.match(regexRU) && fr) {
      return <StyledDescription>{fr}</StyledDescription>;
    } else if (path.match(regexUK) && uk) {
      return <StyledDescription>{uk}</StyledDescription>;
    } else {
      return null;
    }
  };

  return (
    <StaticQuery
      query={graphql`
        {
          product {
            products {
              id
              productName
              price
              amount
              pickupSpot
              chemicalField
              descriptionPL
              descriptionUK
              descriptionEN
              descriptionRU
            }
          }
        }
      `}
      render={({ product: { products } }) => (
        <>
          <ProductList>
            {products.map(
              ({
                productName,
                price,
                amount,
                id,
                pickupSpot,
                chemicalField,
                descriptionPL,
                descriptionUK,
                descriptionEN,
                descriptionRU,
              }) => (
                <>
                  <ProductBlock className="product__information" key={id}>
                    <div
                      className="product__container"
                      style={{
                        padding: "30px 30px 20px",
                        background:
                          'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAM7SURBVHgB7VmLbtswDGSzpY807TIM2P//4NA2bbKuTZ35EHG9MHpQtrYUWA4Q4tiUTFJHUpLP5N/grG/T0D5Rm4RnEyPf9W3bt7dwjd9V+D0Y+G/hvG8X4fdcxuO5b3f25mdpCzhk3rcr2Xm4Jd5SL2wBKHsjO49PIs83fXsJv0qLmewMtegiYyxlR6EDjDUA/aH4deTZr9Aw9ew9yM+MklD6Z5CFE+Z0H7R5kQTGUOiyb18iiqyCIhsjj1n6KrtAtvJo2yAzo+fwfFJ5YIgBMa9bRSxmoQ8bC9lHI88ya9nNSha1BsS8CJo8SCLIglJz+g+5e4l7lrPVkzhQYwCU/ybv2aULL1ll+ixkP1DhVdAiNktaIwA4JeWQPXgNsMpjcATXa6aPVR50eSq8Q5HlPcNjQEz5H5L30K3sKw/KlPjMuryKExOHDDhco7wNcI/yVpetOFEy4ErePelRHqmVAxZ89ygPDKpJJQNu6BoczimPWbo18rkAb4KcAbyeQWEqeXJh5F1pkOCmDSNnAPO45EkYqzkcs7SUenR07V4IpgzAAFNSKJfWdCGnKFEthaYzwBXxWfLgLOUq/wk0nQFeKuQMwIs439fynsGz5knvWUH2QCdpMHXWMow6Cu7rTqkTx/1NQqal92Nju+Ceqgg4TsZ6H2gaxJ6AqkmzHnQyAB4DYny0aTZFsxa6DOrECl1Enl/S9VraoOlaiJezl5HnfK9UJ7xgqrpnNGUAKq/SCMFqvaNr907a0Afg2tNkP8CBeW1epP3cOycHOCW7x80ZwEsCGKBTzFPt9lQBmOUpjelOyTkD9EBV5RbhetDWr4AFXVel5FLqQnVVb8BL2LDwDAwqPgZ2y1q1GCwZgCC9p/+gEmegsdXXnhndSSU8xQMBtUz0GWoAshoOyFh57COqKeldNOnAtqjVVmE9lgTnOW2WzoyyA9YgdqCrOzZUZBi6jbxjGvpeyeFhcM3JxQGGlO/vkp85/TykSMmWzlRdGPuFBl6fmXuluILioEuTIjjWgIegjH4H0wNaNUI/0IFaiBUY3CL1/kGLb2SauwfzeAzG7Mg+BE4GHBsnA46NkwHHxn9pgO6YHuUD4DfBn8L2EvJRygAAAABJRU5ErkJggg==") ',
                        backgroundPositionX: "95%",
                        backgroundPositionY: "10%",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      <p
                        className="product__title"
                        style={{
                          fontSize: "22px",
                          color: "white",
                        }}
                      >
                        {productName}
                      </p>
                      <p
                        className="product__subtitle"
                        style={{
                          fontSize: "16px",
                          color: "white",
                          marginBottom: "0",
                        }}
                      >
                        <FormattedMessage id="services.fieldChemical" />
                      </p>
                      <p
                        className="product__text"
                        style={{
                          fontSize: "12px",
                          color: "white",
                          marginBottom: "20px",
                        }}
                      >
                        {chemicalField}
                        {setDescription(
                          descriptionEN,
                          descriptionPL,
                          descriptionRU,
                          descriptionUK
                        )}
                      </p>
                      <p
                        className="product__subtitle"
                        style={{
                          fontSize: "16px",
                          color: "white",
                          marginBottom: "0",
                        }}
                      >
                        <FormattedMessage id="services.fieldFour" />
                      </p>
                      <p
                        className="product__text"
                        style={{
                          fontSize: "12px",
                          color: "white",
                          marginBottom: "0",
                        }}
                      >
                        {pickupSpot}
                      </p>
                    </div>

                    <div
                      className="product__order"
                      style={{
                        background: "#00000054",
                        display: "flex",
                        justifyContent: "space-between",
                        paddingInline: "30px",
                        paddingBlock: "16px",
                        alignItems: "center",
                        color: "#FFFFFF",
                      }}
                    >
                      <p
                        className="product__min-weight"
                        style={{ margin: "0", fontSize: "12px" }}
                      >
                        <FormattedMessage id="services.fieldTwo" /> : {amount} <FormattedMessage id="services.weight" />
                      </p>
                      {/* <p className="product__price" style={{margin:'0',fontSize:'14px'}}>
                        {<FormattedMessage id="services.checkThePrice" />}
                      </p> */}
                    </div>
                    <button
                      className="btn"
                      onClick={()=>openPopUp(productName)}
                      style={{
                        background: "#00AD11",
                        borderRadius: "0px 0px 4px 4px",
                        fontWeight: "700",
                        fontSize: "16px",
                        lineHeight: "18px",
                        width: "100%",
                        textAlign: "center",
                        paddingBlock: "15px",
                        color: "#FFFFFF",
                        border: "none",
                        position: "absolute",
                        cursor: "pointer",
                      }}
                    >
                      <FormattedMessage id="services.checkThePrice" />
                      {/* <StyledLink to="/contact"></StyledLink> */}
                    </button>
                  </ProductBlock>
                </>
              )
            )}
           
          </ProductList>

        </>
      )}
    />
  );
};

export default ProductsMobile;
