import React from "react"
import { StaticQuery, graphql } from "gatsby"

import ServicesMobileProduct from "../ServicesMobileProduct/ServicesMobileProduct"

const ProductsMobile = () => (
  <StaticQuery
    query={graphql`
      {
        product {
          products {
            id
            productName
            price
            amount
            pickupSpot
            chemicalField
          }
        }
      }
    `}
    render={({ product: { products } }) => (
      <>
        {products.map(
          ({ productName, price, amount, id, pickupSpot, chemicalField }) => (
            <ServicesMobileProduct
              key={id}
              productName={productName}
              price={price}
              amount={amount}
              pickupSpot={pickupSpot}
              chemicalField={chemicalField}
            />
          )
        )}
      </>
    )}
  />
)

export default ProductsMobile
