import React, { useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { FormattedMessage, Link } from "gatsby-plugin-intl"

const StyledWrapper = styled.div`
  min-width: 200px;
  height: auto;
  width: auto;
  max-width: 350px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.services.mobileBackground};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;

  @media (max-width: 700px) {
    max-width: 70vw;
  }
`

const StyledSpan = styled.span`
  text-transform: uppercase;
`

const StyledProductName = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.xxs};
  color: ${({ theme }) => theme.colors.services.textWhite};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-bottom: 3px;
`
const StyledProductDescription = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xxxxs};
  color: ${({ theme }) => theme.colors.services.textGrey};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin: 0;
  height: auto;
  width: auto;
  margin-block-start: 0;
  margin-block-end: 0;
`
const StyledInformationContainer = styled.div`
  display: flex;
  margin-bottom: 3px;
  align-items: center;
  justify-content: center;
`

const StyledInformationTitle = styled(StyledProductName)`
  margin-bottom: 0;
  margin-right: 5px;
  font-size: ${({ theme }) => theme.fontSize.xxxs};
`

const StyledCheckPricesButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  width: auto;
  border: none;
  height: 25px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSize.xxxxs};
  color: ${({ theme }) => theme.colors.services.textBlack};
  background-color: ${({ theme, order }) =>
    order ? "lightblue" : theme.colors.services.buttonBackground};
  cursor: pointer;
  text-transform: uppercase;
`

const ServicesMobileProduct = ({
  productName,
  price,
  amount,
  pickupSpot,
  chemicalField,
}) => {
  const [products] = useState([
    {
      content: productName,
      id: "services.fieldOne",
    },
    {
      content: chemicalField,
      id: "services.fieldChemical",
    },
    {
      content: amount,
      id: "services.fieldTwo",
    },
    {
      content: pickupSpot,
      id: "services.fieldFour",
    },
  ])

  return (
    <>
      <StyledWrapper>
        {products.map(({ content, id }) => (
          <StyledInformationContainer key={id}>
            <StyledInformationTitle>
              <StyledSpan>
                <FormattedMessage id={id} />
              </StyledSpan>
              {": "}
            </StyledInformationTitle>
            <StyledProductDescription>{content}</StyledProductDescription>
          </StyledInformationContainer>
        ))}
        <StyledInformationContainer>
          <StyledInformationTitle>
            <StyledSpan>
              <FormattedMessage id="services.fieldThree" /> {": "}
            </StyledSpan>
          </StyledInformationTitle>
          {price ? (
            <StyledProductDescription>{price}</StyledProductDescription>
          ) : (
            <Link to="/contact/">
              <StyledCheckPricesButton>
                <FormattedMessage id="services.checkThePrice" />
              </StyledCheckPricesButton>
            </Link>
          )}
        </StyledInformationContainer>
        <StyledInformationContainer>
          <StyledInformationTitle>
            <StyledSpan>
              <FormattedMessage id="services.fieldFive" />
            </StyledSpan>
            {": "}
          </StyledInformationTitle>
          <Link to="/contact/">
            <StyledCheckPricesButton order="true">
              <FormattedMessage id="services.fieldFive" />
            </StyledCheckPricesButton>
          </Link>
        </StyledInformationContainer>
      </StyledWrapper>
    </>
  )
}

ServicesMobileProduct.propTypes = {
  productName: PropTypes.string.isRequired,
  price: PropTypes.string,
  amount: PropTypes.number.isRequired,
  pickupSpot: PropTypes.string.isRequired,
}

export default ServicesMobileProduct
